
import { defineComponent } from "vue";
import NavBar from "@/components/NavBar.vue";
import TabBar from "@/components/TabBar.vue";

export default defineComponent({
  components: {
    NavBar,
  },
});
