
export default {
  name: "Card",
  props:{
    img: {
			type: String,
      default: null 
		}
  }
};
