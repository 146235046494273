
import { Options, Vue } from "vue-class-component";
import Card from "@/components/Card.vue";

@Options({
  components: {
    Card
  },
})
export default class Home extends Vue {}
